import React from 'react'
import Home from './components/Home'
import Navbar from './components/Navbar'

import  {BrowserRouter as Router , Routes , Route} from "react-router-dom"
import Projects from './components/Projects'
import Details from './components/Details'
import Header from './components/Header'
import FSLightboxProComponent from './components/FSLightboxProComponent'
import Gallery from './components/Gallery'

const App = () => {
  return (
    <div>
      <Router>
      <Header/>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/projects" element={<FSLightboxProComponent/>} />
        <Route path="/details/:id" element={<Details/>} />

        </Routes>
      </Router>
    </div>
  )
}

export default App
