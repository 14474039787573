 export const pictures = [
    {
        id: 1,
        image:[
        
           { img: "http://localhost:3000/Sign-In.jpeg"},
           { img: "http://localhost:3000/Sign-Up.png"},
           { img: "http://localhost:3000/premium.jpeg"},
           
            
            
        
        ]
    },
    {
        id: 2,
        image:[
         
            { img: "http://localhost:3000/HP7.png"},
            { img: "http://localhost:3000/HP8.png"},
            { img: "http://localhost:3000/HP9.png"},
            { img: "http://localhost:3000/HP10.png"},
           
        ]
    },
    {
        id: 3,
        image:[
         
            { img: "http://localhost:3000/SGH1.jpeg"},
            { img: "http://localhost:3000/SGH2.jpeg"},
            { img: "http://localhost:3000/SGH3.jpeg"},
            { img: "http://localhost:3000/SGH4.jpeg"},
            { img: "http://localhost:3000/SGH5.jpeg"},
           
        ]
    },
    {
        id: 4,
        image:[
         
            { img: "http://localhost:3000/GHY7.png"},
            { img: "http://localhost:3000/GHY8.png"},
            { img: "http://localhost:3000/GHY9.png"},
            { img: "http://localhost:3000/GHY10.png"},
            // { img: "http://localhost:3000/SGH5.jpeg"},
           
        ]
    },
 ]