// src/components/FSLightboxProComponent.jsx
import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import Header from './Header';

const FSLightboxProComponent = () => {
  // Images array with paths relative to the public folder
  const images = [
    '/GHY1.png',
    '/GHY2.png',
    '/GHY3.png',
    '/GHY4.png',
    '/GHY5.png',
    '/GHY6.png',
    // Agar aap videos include karna chahte hain, toh unke URLs yahan add karein
    // 'https://www.youtube.com/watch?v=example',
  ];
  const images1 = [
    '/HP1.jpeg',
    '/HP2.jpeg',
    '/HP3.jpeg',
    '/HP4.jpeg',
    '/HP5.jpeg',
    '/HP6.png',
    
    // Agar aap videos include karna chahte hain, toh unke URLs yahan add karein
    // 'https://www.youtube.com/watch?v=example',
  ];

  const images2 = [
    '/image.png',
    '/Pic22.jpeg',
    '/Pic3.jpeg',
    '/Pic4.jpeg',
    '/Pic5.jpeg',
    '/Pic6.jpeg',
    
    // Agar aap videos include karna chahte hain, toh unke URLs yahan add karein
    // 'https://www.youtube.com/watch?v=example',
  ];

  const images3 = [
    '/Gh1.jpeg',
    '/Gh2.jpeg',
    '/Gh3.jpeg',
    '/Gh4.jpeg',
    '/Gh5.jpeg',
    '/Gh6.jpeg',
    
    // Agar aap videos include karna chahte hain, toh unke URLs yahan add karein
    // 'https://www.youtube.com/watch?v=example',
  ];

  const images4 = [
    '/HPL1.png',
    '/HPL2.png',
    '/HPL3.png',
    '/HPL4.png',

  ];

  // Optional: Captions for each image
  const captions = [
    'Caption for Image 1',
    'Caption for Image 2',
    'Caption for Image 3',
    'Caption for Image 4',
    'Caption for Image 5',
    'Caption for Image 6',
    // Captions for videos
    // 'Caption for Video 1',
  ];

  const [toggler, setToggler] = useState(false);
  const [sourceIndex, setSourceIndex] = useState(1); // FSLightbox uses 1-based index

  const [toggler1, setToggler1] = useState(false);
  const [sourceIndex1, setSourceIndex1] = useState(1);

  const [toggler2, setToggler2] = useState(false);
  const [sourceIndex2, setSourceIndex2] = useState(1);

  const [toggler3, setToggler3] = useState(false);
  const [sourceIndex3, setSourceIndex3] = useState(1);
  
  const [toggler4, setToggler4] = useState(false);
  const [sourceIndex4, setSourceIndex4] = useState(1);
  // Function to open Lightbox at a specific index
  const openLightboxAt = (index) => {
    setSourceIndex(index + 1); // Convert 0-based index to 1-based
    setToggler(!toggler);
  };

  const openLightboxAt1 = (index) => {
    setSourceIndex1(index + 1); // Convert 0-based index to 1-based
    setToggler1(!toggler1);
  };
  const openLightboxAt2 = (index) => {
    setSourceIndex2(index + 1); // Convert 0-based index to 1-based
    setToggler2(!toggler2);
  };

  const openLightboxAt3 = (index) => {
    setSourceIndex3(index + 1); // Convert 0-based index to 1-based
    setToggler3(!toggler3);
  };

  const openLightboxAt4 = (index) => {
    setSourceIndex4(index + 1); // Convert 0-based index to 1-based
    setToggler4(!toggler4);
  };

  return (
    <div>
    <div className="flex flex-col items-center">

      {/* Thumbnails Grid */}
      <h1 className='text-3xl mt-5 font-bold'>INVESTMENT BASE PROJECTS</h1>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-4'>
        {/* {images.map((image, index) => ( */}
          
          <div  className='border rounded-md overflow-hidden shadow-sm'>
            <img 
              src={"./GHY1.png"} 
              alt={`Project Image`} 
              onClick={() => openLightboxAt(0)} 
              className='cursor-pointer w-full h-48 object-cover rounded-md hover:opacity-90 transition-opacity duration-300'
              loading="lazy"
            />
            {/* Optional: Caption Overlay */}
            {/* <div className='p-2'> */}
              {/* <p className='text-center text-sm text-gray-700'></p> */}
            {/* </div> */}
            <div className='mt-4 mb-4'>
      <button type="submit" className="flex justify-center  gap-2 items-center mx-auto shadow-xl text-lg bg-gray-50 backdrop-blur-md lg:font-semibold isolation-auto border-gray-500 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-emerald-500 hover:text-gray-500 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-3 py-1 overflow-hidden border-2 rounded-full group">
<a href="/details/4"> See  Details </a>
  <svg className="w-7 h-7 justify-end group-hover:rotate-90 group-hover:bg-gray-500 text-gray-500 ease-linear duration-300 rounded-full border border-gray-700 group-hover:border-none p-2 rotate-45" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z" className="fill-gray-800 group-hover:fill-gray-800" />
  </svg>
</button>
      </div>
          </div>
          <div  className='border rounded-md overflow-hidden shadow-sm'>
            <img 
              src={"./HP1.jpeg"} 
              alt={`Project Image`} 
              onClick={() => openLightboxAt1(0)} 
              className='cursor-pointer w-full h-48 object-cover rounded-md hover:opacity-90 transition-opacity duration-300'
              loading="lazy"
            />
            <div className='mt-4 mb-4'>
      <button type="submit" className="flex justify-center  gap-2 items-center mx-auto shadow-xl text-lg bg-gray-50 backdrop-blur-md lg:font-semibold isolation-auto border-gray-500 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-emerald-500 hover:text-gray-500 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-3 py-1 overflow-hidden border-2 rounded-full group">
<a href="/details/2"> See  Details </a>
  <svg className="w-7 h-7 justify-end group-hover:rotate-90 group-hover:bg-gray-500 text-gray-500 ease-linear duration-300 rounded-full border border-gray-700 group-hover:border-none p-2 rotate-45" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z" className="fill-gray-800 group-hover:fill-gray-800" />
  </svg>
</button>
      </div>
          </div>
          <div  className='border rounded-md overflow-hidden shadow-sm'>
            <img 
              src={"./image.png"} 
              alt={`Project Image`} 
              onClick={() => openLightboxAt2(0)} 
              className='cursor-pointer w-full h-48 object-cover rounded-md hover:opacity-90 transition-opacity duration-300'
              loading="lazy"
            />
            {/* Optional: Caption Overlay */}
            {/* <div className='p-2'> */}
              {/* <p className='text-center text-sm text-gray-700'>{captions[index]}</p> */}
            {/* </div> */}
            <div className='mt-4 mb-4'>
      <button type="submit" className="flex justify-center  gap-2 items-center mx-auto shadow-xl text-lg bg-gray-50 backdrop-blur-md lg:font-semibold isolation-auto border-gray-500 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-emerald-500 hover:text-gray-500 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-3 py-1 overflow-hidden border-2 rounded-full group">
<a href="/details/1"> See  Details </a>
  <svg className="w-7 h-7 justify-end group-hover:rotate-90 group-hover:bg-gray-500 text-gray-500 ease-linear duration-300 rounded-full border border-gray-700 group-hover:border-none p-2 rotate-45" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z" className="fill-gray-800 group-hover:fill-gray-800" />
  </svg>
</button>
      </div>
          </div>
          <div  className='border rounded-md overflow-hidden shadow-sm'>
            <img 
              src={"./Gh1.jpeg"} 
              alt={`Project Image`} 
              onClick={() => openLightboxAt3(0)} 
              className='cursor-pointer w-full h-48 object-cover rounded-md hover:opacity-90 transition-opacity duration-300'
              loading="lazy"
            />
            
            <div className='mt-4 mb-4'>
      <button type="submit" className="flex justify-center  gap-2 items-center mx-auto shadow-xl text-lg bg-gray-50 backdrop-blur-md lg:font-semibold isolation-auto border-gray-500 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-emerald-500 hover:text-gray-500 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-3 py-1 overflow-hidden border-2 rounded-full group">
<a href="/details/3"> See  Details </a>
  <svg className="w-7 h-7 justify-end group-hover:rotate-90 group-hover:bg-gray-500 text-gray-500 ease-linear duration-300 rounded-full border border-gray-700 group-hover:border-none p-2 rotate-45" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z" className="fill-gray-800 group-hover:fill-gray-800" />
  </svg>
</button>
      </div>
          </div>
          <div  className='border rounded-md overflow-hidden shadow-sm'>
            <img 
              src="./HPL1.png" 
              alt={`Project Image`} 
              onClick={() => openLightboxAt4(0)} 
              className='cursor-pointer w-full h-48 object-cover rounded-md hover:opacity-90 transition-opacity duration-300'
              loading="lazy"
            />
            
            <div className='mt-4 mb-4'>
      <button type="submit" className="flex justify-center  gap-2 items-center mx-auto shadow-xl text-lg bg-gray-50 backdrop-blur-md lg:font-semibold isolation-auto border-gray-500 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-emerald-500 hover:text-gray-500 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-3 py-1 overflow-hidden border-2 rounded-full group">
<a href="/details/3"> See  Details </a>
  <svg className="w-7 h-7 justify-end group-hover:rotate-90 group-hover:bg-gray-500 text-gray-500 ease-linear duration-300 rounded-full border border-gray-700 group-hover:border-none p-2 rotate-45" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z" className="fill-gray-800 group-hover:fill-gray-800" />
  </svg>
</button>
      </div>
          </div>
      </div>

      

      <FsLightbox 
        toggler={toggler} 
        sources={images} 
        slide={sourceIndex} 
        // captions={captions} // Optional: Add captions
        // Additional Pro Features
        // example: enableZoom={true}, enableDownload={true}, etc.
      />
      <FsLightbox 
        toggler={toggler1} 
        sources={images1} 
        slide={sourceIndex1} 
        // captions={captions} // Optional: Add captions
        // Additional Pro Features
        // example: enableZoom={true}, enableDownload={true}, etc.
      />
<FsLightbox 
        toggler={toggler2} 
        sources={images2} 
        slide={sourceIndex2} 
        // captions={captions} // Optional: Add captions
        // Additional Pro Features
        // example: enableZoom={true}, enableDownload={true}, etc.
      />

<FsLightbox 
        toggler={toggler3} 
        sources={images3} 
        slide={sourceIndex3} 
        // captions={captions} // Optional: Add captions
        // Additional Pro Features
        // example: enableZoom={true}, enableDownload={true}, etc.
      />

<FsLightbox 
        toggler={toggler4} 
        sources={images4} 
        slide={sourceIndex4} 
        // captions={captions} // Optional: Add captions
        // Additional Pro Features
        // example: enableZoom={true}, enableDownload={true}, etc.
      />
      
    </div>
    </div>
  );
};

export default FSLightboxProComponent;
